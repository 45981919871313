var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ac-module-heading',[_vm._v(" Approvals ")]),_c('v-card',{staticClass:"card-wrapper"},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('ac-select',{staticClass:"company-select me-3",attrs:{"label":"Company","items":_vm.companies},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}}),_c('ac-button',{attrs:{"title":"Add Entry","icon":_vm.icons.mdiPlus}})],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('ac-button',{staticClass:"me-3",attrs:{"title":"Download Approvals","icon":_vm.icons.mdiDownload}}),_c('ac-input',{staticClass:"staff-list-search me-3",attrs:{"placeholder":"Search","outlined":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.approvalList,"options":_vm.options,"server-items-length":_vm.totalApprovalList,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var fullUserName = ref.item.fullUserName;
return [_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(fullUserName))])]}},{key:"item.approver",fn:function(ref){
        var ref_item_approver_user = ref.item.approver.user;
        var firstname = ref_item_approver_user.firstname;
        var surname = ref_item_approver_user.surname;
return [_c('span',[_vm._v(_vm._s(((firstname || '') + " " + (surname || ''))))])]}},{key:"item.matched",fn:function(ref){
        var matched = ref.item.matched;
return [_c('span',[_vm._v(_vm._s(matched ? 'Yes' : 'No'))])]}},{key:"item.actions",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('ac-icon-btn',{attrs:{"text":"Edit","color":"primary","btn-class":"me-2","icon":_vm.icons.mdiPencil}}),_c('ac-icon-btn',{attrs:{"text":"Delete","color":"error","icon":_vm.icons.mdiDeleteOutline}})],1)]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }