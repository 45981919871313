import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useApprovals() {
  const approvalList = ref([])
  const totalApprovalList = ref(0)
  const loading = ref(false)
  const searchQuery = ref('')
  const isDialogVisible = ref(false)
  const companies = ref(['Company 1', 'Company 2', 'Company 3'])
  const selectedCompany = ref('')

  const headers = [
    { text: 'User', value: 'user' },
    { text: 'Account Number', value: 'accountNumber' },
    { text: 'Broker', value: 'broker' },
    { text: 'Approver', value: 'approver' },
    { text: 'Security', value: 'security' },
    { text: 'GTC Order', value: 'gtc' },
    { text: 'Buy/Sell', value: 'details' },
    { text: 'Investment Class', value: 'investmentClass' },
    { text: 'Ticker', value: 'symbol' },
    { text: 'Exchange', value: 'exchange' },
    { text: 'Approval Date', value: 'approvalDate' },
    { text: 'Matched', value: 'matched' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const fetchApprovalList = () => {
    store
      .dispatch('pa-dealing/fetchApprovalList', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(data => {
        approvalList.value = data.docs
        totalApprovalList.value = data.totalItems

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    fetchApprovalList()
  })

  return {
    headers,
    options,
    approvalList,
    totalApprovalList,
    isDialogVisible,
    companies,
    selectedCompany,
    searchQuery,
    loading,
  }
}
